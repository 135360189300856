@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&display=swap');

html, 
body,
#root,
.app {
  height: 100%;
  width: 100%;
  font-family: "Inter", sans-serif;
} 

::-webkit-scrollbar {
  width: 0.6rem;
}

::-webkit-scrollbar-track {
  background: #f6f6f6;
}

::-webkit-scrollbar-thumb {
  background: #858585;
}

::-webkit-scrollbar-track:hover {
  background: #E8E8E8;
}
